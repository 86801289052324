import React, { useCallback, useState, useMemo } from 'react';
import { AppBar, Box, useMediaQuery, Container, Theme, Paper, Drawer, Grid, Typography, Avatar, makeStyles } from '@material-ui/core';
import { useCartContext } from 'src/context/CartContext/CartContextProvider';
import { useHistory } from 'react-router';
import { UserIfnoInitialValue, useGeneralContext, useRolesContext } from 'src/context';
import { Link } from 'react-router-dom';
import { SITE_URL } from 'src/app/const';
import { uniqBy } from 'lodash';
import { Category } from 'src/app/models';
import { useCategoryContext } from 'src/app/business';
import { ListMenu } from 'src/commons/ui/view/GenericMenu';
import { LOGIN_ENABLED } from 'src/config';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { logout } from 'src/platform/libby/session';
import { AppBarMobile } from './components/AppHeaderMobile';
import { AppHeaderWeb } from './components/AppHeaderWeb';
import { MenuDrawer } from '../MenuDrawer';
import { useIsPromotionPath } from '../../../../utils';

const useStyle = makeStyles((theme) => ({
  resultResume: {
    padding: 16,
    '@media (max-width:48em)': {
      background: theme.palette.primary.dark,
      '& .MuiTypography-colorTextSecondary': {
        color: 'white !important',
      },
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: '18em',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '18em',
  },
  avatar: {
    width: '31px',
    height: '31px',
  },
  linkLogin: { textDecoration: 'none' },
  zindex: {
    zIndex: theme.zIndex.appBar,
  },
  linkTo: {
    color: 'white',
  },
}));

export const AppHeader = () => {
  const classes = useStyle();
  const { dispatch } = useGeneralContext();
  const [searchValue, setSearchValue] = useState('');
  const [mobileInputVisible, setMobileInputVisible] = useState(false);
  const [openMainMenu, setOpenMainMenu] = useState(false);
  const { userInfo, selectedRole, setUserInfo, deselectRole } = useRolesContext();
  const isDownSM = useMediaQuery<any>((theme) => theme.breakpoints.down('sm'));
  const { cartState } = useCartContext();
  const history = useHistory();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const Wrapper = isDownSm ? React.Fragment : Container;
  const isPromotionPath = useIsPromotionPath();

  const onCartClick = useCallback(() => history.push('/cart'), [history]);
  
  const onOpenMenu = useCallback(() => {
    if (isDownSm) {
      setOpenMainMenu(true);
    } else {
      dispatch.setSearch('');
      history.push('/search');
    }
  }, [isDownSm, history, dispatch]);

  const onOpenCategories = useCallback(
    (item: ListMenu) => {
      dispatch.setSearch(item.name);
      history.push(item.url);
      setSearchValue('');
    },
    [history, dispatch],
  );

  const onSearchChange = useCallback(
    (e) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue],
  );

  const onSearchClick = useCallback(() => {
    dispatch.setSearch(searchValue);
    setSearchValue('');
    setTimeout(() => {
      history.push(!searchValue ? '/search' : `/search/${searchValue.trim()}`);
    }, 750);
  }, [history, searchValue, dispatch, setSearchValue]);

  const onSingOut = useCallback(async () => {
    await logout();
    deselectRole();
    setUserInfo(UserIfnoInitialValue);
    history.push('/');
    window.location.reload();
  }, [history, setUserInfo, deselectRole]);

  const onLogoClick = useCallback(() => {
    if (!isPromotionPath) history.push('/');
  }, [history, isPromotionPath]);

  const toggleInput = useCallback(() => setMobileInputVisible((prev) => !prev), [setMobileInputVisible]);

  const { data: dataAllCategory } = useCategoryContext();

  const [contentMenu, setContentMenu] = useState<ListMenu[]>([]);

  const [contentMenuAux, setContentMenuAux] = useState<ListMenu[][]>([]);

  const onHandleOpenMainMenu = useCallback(() => {
    setOpenMainMenu(false);
  }, []);

  const contents = useMemo(() => {
    const categoryFilter: ListMenu[] = contentMenu.length
      ? contentMenu
      : uniqBy(dataAllCategory, (category: Category) => category.id).map((category) => ({
          name: category.name,
          value: category.id,
          url: `/categories/${category.id}`,
          icon: category.config_values.icon,
          relevance: category.relevance,
          in_header: category.in_header
        }))
    return categoryFilter;
  }, [dataAllCategory, contentMenu]);

  const onAddFilter = useCallback(
    (item: ListMenu) => {
      history.push(item.url);
      setContentMenu([]);
      setContentMenuAux([]);
      onHandleOpenMainMenu();
    },
    [history, onHandleOpenMainMenu],
  );

  const onSubMenu = useCallback(
    (items: ListMenu[]) => {
      setContentMenuAux((prevContentMenu) => {
        const copyPrevContentMenu = [...prevContentMenu];
        copyPrevContentMenu.push(contents);
        return copyPrevContentMenu;
      });
      setContentMenu(items);
    },
    [contents],
  );

  const onReturnContentMenu = useCallback(() => {
    const copyPrevContentMenu = [...contentMenuAux];
    setContentMenu(copyPrevContentMenu[copyPrevContentMenu.length - 1]);
    copyPrevContentMenu.pop();
    setContentMenuAux(copyPrevContentMenu);
  }, [contentMenuAux]);

  const content = (
    <Grid>
      <Grid className={classes.resultResume}>
        {LOGIN_ENABLED ? (
          <Grid container>
            <Grid item xs={3}>
              <Avatar className={classes.avatar} src="/broken-image.jpg" />
            </Grid>
            <Grid item xs={9}>
              {selectedRole.rol_id === '' ? (
                <Box mt={1} color="primary.contrastText">
                  <Link className={classes.linkLogin} to="/login" onClick={onHandleOpenMainMenu}>
                    <Box color="primary.contrastText">
                      <Typography variant="subtitle1">Ingresá/Registrate</Typography>
                    </Box>
                  </Link>
                </Box>
              ) : (
                <Box mt={1} color="primary.contrastText">
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography variant="subtitle1">{userInfo.name.split(' ')[0]}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Link className={[classes.linkLogin, classes.linkTo].join(' ')} to="/dashboard" onClick={onHandleOpenMainMenu}>
                        <ChevronRightIcon color="inherit" />
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        ) : (
          <Typography variant="subtitle1" color="textSecondary">
            Bienvenido
          </Typography>
        )}
      </Grid>
      <MenuDrawer
        contents={contents}
        onAddFilter={onAddFilter}
        onRemoveFilter={() => {}}
        onSubMenu={onSubMenu}
        contentMenuAux={contentMenuAux}
        onReturnContentMenu={onReturnContentMenu}
        onSingOut={onSingOut}
      />
    </Grid>
  );

  return (
    <AppBar elevation={0}>
      <Paper square elevation={2}>
        <Container maxWidth={false} id="back-to-top-anchor">
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
            open={openMainMenu}
            onClose={onHandleOpenMainMenu}
          >
            {content}
          </Drawer>
          <Wrapper>
            <Box justifyContent="center" bgcolor="primary.contrastText">
              {isDownSM ? (
                <AppBarMobile
                  quantityItems={cartState.totals.quantity}
                  onSearchClick={onSearchClick}
                  onCartClick={() => history.push('/cart')}
                  inputVisible={mobileInputVisible}
                  toggleInput={toggleInput}
                  onSearchChange={onSearchChange}
                  searchValue={searchValue}
                  onOpenMenu={onOpenMenu}
                  onLogoClick={onLogoClick}
                />
              ) : (
                <AppHeaderWeb
                  contents={contents}
                  quantityItems={cartState.totals.quantity}
                  links={[
                    {
                      name: 'Nosotros',
                      onClick: () => history.push(SITE_URL.NOSOTROS),
                    },
                    {
                      name: 'Preguntas frecuentes',
                      onClick: () => history.push(SITE_URL.PREGUNTAS_FRECUENTES),
                    },
                  ]}
                  onCartClick={onCartClick}
                  onOpenMenu={onOpenMenu}
                  onOpenCategories={onOpenCategories}
                  onSearhChange={onSearchChange}
                  onSearchClick={onSearchClick}
                  onSingOut={onSingOut}
                  onLogoClick={onLogoClick}
                  searchValue={searchValue}
                />
              )}
            </Box>
          </Wrapper>
        </Container>
      </Paper>
    </AppBar>
  );
};
