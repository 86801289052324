import React from 'react';
import { CardContent, Box, Typography, Grid } from '@material-ui/core';
import { formatPriceNotDecimal } from 'src/utils';
import { SPACING } from 'src/commons';
import { Product } from 'src/app/models/business/Product';
import { PaymentExtraDataModel } from 'src/app/models';
import { PriceView } from 'src/commons/components/PriceView';
import { productCardStyles as useStyles } from '../../styles';
import { CardDiscount } from '../CardDiscount/CardDiscount';
import { FavoriteButtonView } from '../FavoriteButtonView';
import ProductWithOutStock from './ProductWithOutStock';

interface ProductCardContentPros {
  data: Product;
  discount: number;
  mainPrice: PaymentExtraDataModel;
  alterPrice: PaymentExtraDataModel;
  mainAmount: 'amount' | 'original_amount';
  isFavorite: boolean;
  onAddFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRemoveFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  enableStock: boolean;
}

export const ProductCardContent = ({
  data,
  discount,
  mainPrice,
  mainAmount,
  alterPrice,
  isFavorite,
  onRemoveFavorite,
  onAddFavorite,
  enableStock,
}: ProductCardContentPros) => {
  const classes = useStyles();
  const tax = (data.vat || 0) / 100;

  return (
    <CardContent>
      <Box mt={-3}>
        <Grid item xs zeroMinWidth style={{ marginBottom: '8px' }}>
          <Typography className={classes.titleTextOnCard} variant="body1" color="textSecondary" id={`${data.productId.toString()}-name`}>
            {data.name}
          </Typography>
        </Grid>

        {enableStock ? (
          <>
            <Box minHeight="18px">
              <Box color="text.disabled" display="flex" flexDirection="row">
                <Grid item container>
                  {!!parseInt(data.old_price, 10) && (
                    <Typography className={classes.oldPrice} variant="body2" id={`${data.productId.toString()}-old-price`}>
                      {`$${formatPriceNotDecimal(parseInt(data.old_price, 10))}`}
                    </Typography>
                  )}
                  {discount > 0 && (
                    <CardDiscount isAbsolute={false} semiBorderRadius={false} discount={discount} padding="2px 6px" productId={data.productId} />
                  )}
                </Grid>
                <Grid item container xs={2} style={{ position: 'relative' }}>
                  <FavoriteButtonView isFavorite={isFavorite} top={0} right={-10} onAddFavorite={onAddFavorite} onRemoveFavorite={onRemoveFavorite} />
                </Grid>
              </Box>
            </Box>

            <Typography variant="h5" color="textPrimary" id={`${data.productId.toString()}-price`}>
              {`$${formatPriceNotDecimal(mainPrice[mainAmount])}`}
            </Typography>

            <Typography className={classes.taxPriceCarrousel} id={`${data.productId.toString()}-price`}>
              Precio sin impuestos nacionales{' '}
              {tax
                ? `$${formatPriceNotDecimal(mainPrice[mainAmount] - mainPrice[mainAmount] * tax)}`
                : `$${formatPriceNotDecimal(mainPrice[mainAmount])}`}
            </Typography>

            <Grid container direction="column" spacing={SPACING.SHORT}>
              <Grid item xs zeroMinWidth>
                <PriceView
                  variant="body2"
                  key={`${alterPrice.amountTotal}-best-promos`}
                  id={`${data.productId.toString()}-best-promos`}
                  color="primary"
                  beforeText={`${alterPrice.installments} ${alterPrice.typePayment}  de`}
                  afterText={`${alterPrice.discount > 0 ? `(${alterPrice.discount}% OFF)` : ''}${
                    alterPrice.installments > 1 && alterPrice.interest === 0 ? 'sin interés' : ''
                  }`}
                  withDecimals={alterPrice.installments > 1}
                  price={alterPrice.amountTotal}
                  afterProps={{ pl: alterPrice.installments > 1 ? 1.7 : 0.5 }}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <ProductWithOutStock />
        )}
      </Box>
    </CardContent>
  );
};
