import React from 'react';
import { Button, Grid, Typography, Divider, Box, useMediaQuery, Theme } from '@material-ui/core';
import { formatPrice, validateFreeShipping } from 'src/utils';
import { CartTotal, CartItemStock } from 'src/context/CartContext/types';
import { productCardStyles as useStyles } from '../../styles';

export interface CartResumeProps {
  cartTotals: CartTotal;
  shipping: boolean;
  onBuyClick: () => void;
  products: CartItemStock[];
}

export const CartResume = ({ cartTotals, shipping, onBuyClick, products }: CartResumeProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const publications = products.map((item) => ({
    ...item.publication,
    calculatedPrices: item.publication.price.map((promo) => ({
      promotion_id: promo.promotion_id,
      priceTax: item.publication.vat ? Math.round(Number(promo.price) * (item.publication.vat / 100)) : 0,
    })),
  }));
  const totalPriceTax = publications.reduce((acc, pub) => {
    if (pub.calculatedPrices.length > 0) {
      return acc + pub.calculatedPrices[0].priceTax;
    }
    return acc;
  }, 0);

  const subTotalTax = totalPriceTax / cartTotals.subTotal;

  return (
    <Box border="1px solid" mb={2} mt={isDownSm ? 2 : 0} borderColor="other.stroke" borderRadius={8} boxShadow={3}>
      <Grid container direction="column">
        <Box padding={2}>
          <Grid item>
            <Typography variant="h5" component="h2">
              <Box component="span" color="primary.dark">
                Resumen
              </Box>
            </Typography>

            <Grid container justify="space-between">
              <Typography className={classes.taxPriceTotal} variant="caption">
                Precio sin impuestos nacionales
              </Typography>
              <Typography className={classes.taxPriceTotal} variant="body2">
                {subTotalTax > 0
                  ? `$${formatPrice(cartTotals.subTotal - cartTotals.subTotal * subTotalTax)}`
                  : `$${formatPrice(cartTotals.subTotal)}`}
              </Typography>
            </Grid>

            <Grid container justify="space-between">
              <Typography variant="body1" color="textPrimary">
                Subtotal
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {`$${formatPrice(cartTotals.subTotal)}`}
              </Typography>
            </Grid>

            <Grid container justify="space-between">
              <Typography variant="body1" color="textPrimary">
                Costo de envío
              </Typography>
              {validateFreeShipping(cartTotals.subTotal.toString() || cartTotals.totalPrice.toString()) && !shipping ? (
                <Typography variant="body2" color="textPrimary">
                  <Box component="span" color="success.dark">
                    Gratis
                  </Box>
                </Typography>
              ) : (
                <Typography variant="body2" color="textPrimary">
                  <Box component="span" color="success.dark">
                    a definir
                  </Box>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>

        <Divider />
        <Box padding={2}>
          <Grid item>
            <Grid container justify="space-between">
              <Typography variant="h5" component="h2">
                <Box component="span" color="primary.dark">
                  Total
                </Box>
              </Typography>
              <Typography variant="h5" color="textPrimary">
                {`$${formatPrice(cartTotals?.totalPrice)}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Box mt={2}>
              <Button fullWidth size="large" variant="contained" color="primary" onClick={() => onBuyClick()}>
                Comprar
              </Button>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};
