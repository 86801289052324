import React from 'react';
import { Grid, Typography, Box, useMediaQuery, Theme, Button } from '@material-ui/core';
import { EditQuantity, CartTotal, CartItemStock } from 'src/context/CartContext/types';
import { useHistory } from 'react-router-dom';
import { CartItem as Product } from 'src/app/models';
import { KeyboardArrowLeftRounded } from '@material-ui/icons';
import { CartItem } from '../CartItem';
import { CartResume } from '../CartResume';
import { CartItemMobile } from '../CartItem/CartItemMobile';
import { CartEmpy } from './CartEmpy';

interface CartViewProps {
  products: CartItemStock[];
  dataResume: CartTotal;
  shipping: boolean;
  onChangeQuantity: (value: EditQuantity) => void;
  onDeleteItem: (item: Product) => void;
  onBuyClick: () => void;
}

export const CartView = ({ products, dataResume, shipping, onChangeQuantity, onDeleteItem, onBuyClick }: CartViewProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const history = useHistory();
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2} />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Typography variant={isDownSm ? 'h6' : 'h4'} color="textPrimary">
            <Box mb={2} fontWeight="bold">
              Mi carrito
            </Box>
          </Typography>
        </Grid>
        {products.length ? (
          <Grid item container direction="row" spacing={isDownSm ? 0 : 2} xs={12}>
            <Grid item lg={8} md={8} xs={12}>
              {products.map((product) => (
                <Box
                  display="flex"
                  key={product.publication.id}
                  border="1px solid"
                  borderColor="other.stroke"
                  borderRadius={8}
                  padding={1}
                  marginBottom={1}
                >
                  {!isDownSm ? (
                    <CartItem
                      product={product}
                      onChangeQuantity={(newQuantity) =>
                        onChangeQuantity({
                          id: product.publication.id,
                          newQuantity: product.quantity + newQuantity,
                          publication: product.publication,
                        })
                      }
                      onDeleteItem={() => onDeleteItem(product)}
                      enableStock={!!(product && product?.stock && product?.stock > 0)}
                    />
                  ) : (
                    <CartItemMobile
                      product={product}
                      onChangeQuantity={(newQuantity) =>
                        onChangeQuantity({
                          id: product.publication.id,
                          newQuantity: product.quantity + newQuantity,
                          publication: product.publication,
                        })
                      }
                      onDeleteItem={() => onDeleteItem(product)}
                      enableStock={!!(product && product?.stock && product?.stock > 0)}
                    />
                  )}
                </Box>
              ))}

              <Grid container direction="row">
                <Grid item>
                  <Button
                    component="a"
                    onClick={() => history.push('/')}
                    size="small"
                    color="primary"
                    startIcon={<KeyboardArrowLeftRounded fontSize="large" color="primary" />}
                    style={{ textTransform: 'none', marginBottom: 16 }}
                  >
                    <Typography variant="body2" color="primary">
                      Seguir comprando
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <CartResume shipping={shipping} cartTotals={dataResume} onBuyClick={onBuyClick} products={products} />
            </Grid>
          </Grid>
        ) : (
          <CartEmpy /> 
        )}
      </Grid>
    </Grid>
  );
};
