import { ConfigValuesPublication, Publication, PaymentExtraDataModel, defaultConfigValues } from 'src/app/models';
import { getPaymentByConfigAmount } from 'src/commons/hooks/useBestPromotions';
import { Product } from 'src/app/models/business/Product';
import { orderBy } from 'lodash';

interface InfoPublicationPayment {
  mainAmount: 'original_amount' | 'amount';
  alterAmount: 'original_amount' | 'amount';
  mainPrice: PaymentExtraDataModel;
  alterPrice: PaymentExtraDataModel;
  main_price: boolean;
  tax?: number;
}

export const useInfoPublicationPayment = (data?: Publication | Product): InfoPublicationPayment => {
  const bestPromotions = orderBy(data?.best, ['installments', 'amount']).reverse();
  const tax = (data?.vat || 0) / 100
  const {
    showPrice: { main_amount, main_price, alter_amount, alter_price },
  }: ConfigValuesPublication = {
    ...defaultConfigValues,
    ...(data?.config_values || {}),
    showPrice: { ...defaultConfigValues.showPrice, ...(data?.config_values?.showPrice || {}) },
  };
  const mainPrice = !main_price
    ? bestPromotions.find((item) => item.installments > 1) || bestPromotions[0]
    : bestPromotions.find((item) => item.installments === 1) || bestPromotions[0];
  const alterPrice = !alter_price
    ? bestPromotions.find((item) => item.installments > 1) || bestPromotions[0]
    : bestPromotions.find((item) => item.installments === 1) || bestPromotions[0];
  return {
    mainPrice: getPaymentByConfigAmount(mainPrice, main_amount),
    mainAmount: main_amount,
    alterPrice: getPaymentByConfigAmount(alterPrice, alter_amount),
    alterAmount: alter_amount,
    main_price,
    tax
  };
};

export const getInfoPublicationPayment = (data?: Publication | Product): InfoPublicationPayment => {
  const {
    showPrice: { main_amount, main_price, alter_amount, alter_price },
  }: ConfigValuesPublication = {
    ...defaultConfigValues,
    ...(data?.config_values || {}),
    showPrice: { ...defaultConfigValues.showPrice, ...(data?.config_values?.showPrice || {}) },
  };
  const tax = (data?.vat || 0) / 100
  const orderBest = orderBy(data?.best, ['installments', 'amount']).reverse();
  const mainPrice = !main_price
    ? orderBest.find((item) => item.installments > 1) || orderBest[0]
    : orderBest.find((item) => item.installments === 1) || orderBest[0];
  const alterPrice = !alter_price
    ? orderBest.find((item) => item.installments > 1) || orderBest[0]
    : orderBest.find((item) => item.installments === 1) || orderBest[0];
  return {
    mainPrice: getPaymentByConfigAmount(mainPrice, 'amount'),
    mainAmount: main_amount,
    alterPrice: getPaymentByConfigAmount(alterPrice, alter_amount),
    alterAmount: alter_amount,
    main_price,
    tax
  };
};
