import { fade, makeStyles } from '@material-ui/core';

export const productCardStyles = makeStyles((theme) => ({
  container: {
    width: 265,
    height: 430,
    maxHeight: 430,
    marginBottom: '10px',
    position: 'relative'
  },
  containerHover: {
    position: 'relative',
  },
  backdrop: {
    zIndex: 2,
    color: '#fff',
    position: 'absolute',
    background: fade(theme.palette.primary.dark, 0.7),
  },
  box: {
    overflow: 'hidden',
  },
  installmentsContainer: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
  },
  installmentsInnerContainer: {
    background: '#00D1F7',
    padding: '4px 12px',
    display: 'flex',
    flexDirection: 'column',
    borderBottomLeftRadius: 5,
  },
  installmentsTitle: {
    color: 'white',
    fontWeight: 600,
  },
  installmentsSubtitle: {
    color: 'white',
    fontWeight: 600,
    fontSize: 11,
    marginTop: '-5px',
  },
  priceWithDiscountContainer: {
    height: 30,
    margin: '0px -4px 5px',
  },
  priceText: {
    textDecoration: 'line-through',
    color: '#8492A6',
    fontSize: 12,
    fontWeight: 400,
  },
  priceWithDiscountText: {
    color: '#3C4858',
    fontSize: 20,
    fontWeight: 400,
  },
  titleText: {
    fontSize: '16px',
  },
  titleTextOnCard: {
    fontSize: '16px',
    lineHeight: '1.5em',
    height: '3em',
    overflow: 'hidden',
  },
  oldPrice: {
    textDecoration: 'line-through',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  iconLocalShipping: { width: 15, height: 15 },
  productName: {
    width: '100%',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    background: '#fff',
  },
  withOutStock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 12px',
    gap: '8px',
    width: '100%',
    maxWidth: '233px',
    height: '50px',
    background: '#E1E1E1',
    color: '#FF7733',
    borderLeft: '4px solid #FF7733',
    borderRadius: '5px',
    flex: 'none',
    order: 3,
    alignSelf: 'stretch',
    flexGrow: 0,
    marginTop: '27px',
    marginBottom: '27px',
  },
  taxPriceText: {
    color: '#5F5F5F',
    fontWeight: 200,
  },
  taxPriceTotal: {
    color: '#A8A8A8',
    fontWeight: 200,
  },
  taxPriceCarrousel: {
    color: '#5F5F5F',
    fontSize: '11px',
    fontWeight: 200,
  }
}));
