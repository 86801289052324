import { CardData, PaymentSimulation } from 'src/app/models';
import { filterInterestRate } from '../helpers';
import { BankNoInterest, BankOptions, PaymentMethodProps } from '../interfaces';

export const useBankOptions = ({ data, bankOptions }: PaymentMethodProps) => {
  const bankMapper: Map<number, { bankOptions: BankOptions[]; bankNoInterest: BankNoInterest[] }> = new Map<
    number,
    { bankOptions: BankOptions[]; bankNoInterest: BankNoInterest[] }
  >();
  const validation24 = ['AHORA 24', 'AHORA 30', '24 CUOTAS FIJAS', 'AHORA 12'];
  const filteredBy24 = data.targets.filter((promo) => validation24.includes(promo));
  // recorro el array con las opciones bancarias, valido que no sean de 24.
  bankOptions.forEach((bank: PaymentSimulation) => {
    if (bank.options) {
      Object.entries(bank.options).forEach(([key, value]) => {
        const formatValue = value as CardData;
        formatValue.payments.forEach((payment) => {
          if (filteredBy24) {
            // guardo en un map los payment y como key uso las cuotas para agrupar los payment que coincidan.
            const validationMapper: any = bankMapper.has(payment.installments)
              ? bankMapper.get(payment.installments)
              : { bankOptions: [], bankNoInterest: [] };

            const object: any = {
              installments: payment.installments,
              amount: Math.round(payment.amount),
              emiter: +key,
              emiter_img_url: formatValue.card.emiter_image_url,
              type: formatValue.card.type,
              card_id: +formatValue.card.card_id,
              targets: filteredBy24,
              discount: payment.total_discount,
              original_amount: payment.original_amount
            };
            // creo un objeto para los payment sin interes.
            if (payment.amount === payment.original_amount) {
              // aqui es donde se guardan;
              bankMapper.set(payment.installments, {
                bankOptions: [...validationMapper.bankOptions],
                bankNoInterest: [...validationMapper.bankNoInterest, object],
              });
              // por descarte entran acá los payment con intereses.
            } else if (Math.sign(payment.amount - payment.original_amount) === 1) {
              object.interest = Math.round(payment.amount - payment.original_amount);
              // comprabas cual es el que tiene menor interes
              bankMapper.set(payment.installments, {
                bankOptions: [...validationMapper.bankOptions, object],
                bankNoInterest: [...validationMapper.bankNoInterest],
              });
            } else if (payment.amount + (payment.total_discount ?? 0) <= payment.original_amount) {
              bankMapper.set(payment.installments, {
                bankOptions: [...validationMapper.bankOptions],
                bankNoInterest: [...validationMapper.bankNoInterest, object],
              });
            } else {
              bankMapper.set(payment.installments, {
                bankOptions: [...validationMapper.bankOptions, object],
                bankNoInterest: [...validationMapper.bankNoInterest],
              });
            }
          }
        });
      });
    }
  });

  // esta función checkea si existe, si existe = comparo con el existente, si no existe = lo seteo
  filterInterestRate(bankMapper);

  // el map lo convierto en array para manejarlo mas fácilmente y lo ordeno de mayor a menor por cantidad de cuotas.
  const sortedMap = [...bankMapper].sort((a: any, b: any) => b[0] - a[0]);

  // accedemos y traemos todas las cuotas de 1 solo pago para la configuracion de metodo de un pago
  const sortedOneInstallment = sortedMap[sortedMap.length - 1]    
  const allOneInstallment = sortedOneInstallment?.[1].bankNoInterest 

  const options: { installments: number; options: BankNoInterest[] }[] = [];
  const usedNoInterest: number[] = [];
  const usedInterest: number[] = [];
  // configuracion de los destacados a mostrar = default matchea las mejores ya sean sin interes o fijas
  // fijas = cuotas con interes
  // sin interes.
  const destacated: string = data.config_values.bank_config;
  const { card_id, singlePayOption } = data.config_values;
  sortedMap.forEach(([installments, group]) => {
    const bankNoInterest = group.bankNoInterest.sort((a, b) => a.amount - b.amount || b.discount - a.discount);
    const bankOptions = group.bankOptions.sort((a, b) => a.amount - b.amount || b.discount - a.discount);
  
    if (destacated === 'Sin Interes' && bankNoInterest.length) {
      const validOptions = bankNoInterest.filter((item) => !usedNoInterest.includes(item.card_id));
      const discountedOptionsNoInt = bankNoInterest.filter((item) => item.discount).sort((a, b) => b.discount - a.discount || a.amount - b.amount);

      if (discountedOptionsNoInt.length) {
        const maxDiscount = Math.max(...discountedOptionsNoInt.map((opt) => opt.discount));
        const firstOptions = discountedOptionsNoInt.filter((item) => !usedNoInterest.includes(item.card_id) && item.discount === maxDiscount);
        const secondBestDiscount = Math.max(...discountedOptionsNoInt.filter((item) => item.discount < maxDiscount).map((opt) => opt.discount));
        const secondOptions = discountedOptionsNoInt.filter((item) => item.discount === secondBestDiscount && !usedNoInterest.includes(item.card_id));

        if (firstOptions.length) {
          // Aca entrarian como primera opcion las tarjetas con mayor descuento y mayor cuota
          options.push({ installments, options: firstOptions });
          usedNoInterest.push(...firstOptions.map((item) => item.card_id));
        }
        if (secondOptions.length) {
          // Aca entrarian como segunda opcion las tarjetas con segundo mayor descuento y mayor cuota
          options.push({ installments, options: secondOptions });
          usedNoInterest.push(...secondOptions.map((item) => item.card_id));
        }
      } else {
        const validNoDiscount = validOptions.filter((item) => !item.discount);
        options.push({ installments, options: validNoDiscount }); // Aca entran las que no tienen descuento
        usedNoInterest.push(...validNoDiscount.map((item) => item.card_id));
      }
    } else if (destacated === 'Cuotas Fijas' && bankOptions.length) {
      const validOptions = bankOptions.filter((item) => !usedInterest.includes(item.card_id));
      const discountedOptionsInt = bankOptions.filter((item) => item.discount).sort((a, b) => b.discount - a.discount || a.amount - b.amount);

      // CON INTERES

      if (discountedOptionsInt.length) {
        const maxDiscount = Math.max(...discountedOptionsInt.map((opt) => opt.discount));
        const firstOptions = discountedOptionsInt.filter((item) => !usedInterest.includes(item.card_id) && item.discount === maxDiscount);
        const secondBestDiscount = Math.max(...discountedOptionsInt.filter((item) => item.discount < maxDiscount).map((opt) => opt.discount));
        const secondOptions = discountedOptionsInt.filter((item) => item.discount === secondBestDiscount && !usedInterest.includes(item.card_id));

        if (firstOptions.length) {
          // Aca entrarian como primera opcion las tarjetas con mayor descuento y mayor cuota
          options.push({ installments, options: firstOptions });
          usedInterest.push(...firstOptions.map((item) => item.card_id));
        }
        if (secondOptions.length) {
          // Aca entrarian como segunda opcion las tarjetas con segundo mayor descuento y mayor cuota
          options.push({ installments, options: secondOptions });
          usedInterest.push(...secondOptions.map((item) => item.card_id));
        }
      } else {
        const validNoDiscount = validOptions.filter((item) => !item.discount);
        options.push({ installments, options: validNoDiscount }); // Aca entran las que no tienen descuento
        usedInterest.push(...validNoDiscount.map((item) => item.card_id));
      }
    } else {
      // SIN INTERES
      const validNoInterest = bankNoInterest.filter((item) => !usedNoInterest.includes(item.card_id));
      const discountedOptionsNoInt = bankNoInterest.filter((item) => item.discount).sort((a, b) => b.discount - a.discount || a.amount - b.amount);
      
      if (discountedOptionsNoInt.length) {
        const maxDiscount = Math.max(...discountedOptionsNoInt.map((opt) => opt.discount));
        const firstOptions = discountedOptionsNoInt.filter((item) => !usedNoInterest.includes(item.card_id) && item.discount === maxDiscount);
        const secondBestDiscount = Math.max(...discountedOptionsNoInt.filter((item) => item.discount < maxDiscount).map((opt) => opt.discount));
        const secondOptions = discountedOptionsNoInt.filter((item) => item.discount === secondBestDiscount && !usedNoInterest.includes(item.card_id));

        if (firstOptions.length) {
          // Aca entrarian como primera opcion las tarjetas con mayor descuento y mayor cuota
          options.push({ installments, options: firstOptions });
          usedNoInterest.push(...firstOptions.map((item) => item.card_id));
        }
        if (secondOptions.length) {
          // Aca entrarian como segunda opcion las tarjetas con segundo mayor descuento y mayor cuota
          options.push({ installments, options: secondOptions });
          usedNoInterest.push(...secondOptions.map((item) => item.card_id));
        }
      } else {
        const validNoDiscount = validNoInterest.filter((item) => !item.discount);
          options.push({ installments, options: validNoDiscount }); // Aca entran las que no tienen descuento
          usedNoInterest.push(...validNoDiscount.map((item) => item.card_id));
      }

      const validInterest = bankOptions.filter((item) => !usedInterest.includes(item.card_id));
      const discountedOptionsInt = bankOptions.filter((item) => item.discount).sort((a, b) => b.discount - a.discount || a.amount - b.amount);

      // CON INTERES

      if (discountedOptionsInt.length) {
        const maxDiscount = Math.max(...discountedOptionsInt.map((opt) => opt.discount));
        const firstOptions = discountedOptionsInt.filter((item) => !usedInterest.includes(item.card_id) && item.discount === maxDiscount);
        const secondBestDiscount = Math.max(...discountedOptionsInt.filter((item) => item.discount < maxDiscount).map((opt) => opt.discount));
        const secondOptions = discountedOptionsInt.filter((item) => item.discount === secondBestDiscount && !usedInterest.includes(item.card_id));

        if (firstOptions.length) {
          // Aca entrarian como primera opcion las tarjetas con mayor descuento y mayor cuota
          options.push({ installments, options: firstOptions });
          usedInterest.push(...firstOptions.map((item) => item.card_id));
        }
        if (secondOptions.length) {
          // Aca entrarian como segunda opcion las tarjetas con segundo mayor descuento y mayor cuota
          options.push({ installments, options: secondOptions });
          usedInterest.push(...secondOptions.map((item) => item.card_id));
        }
      } else {
        const validNoDiscount = validInterest.filter((item) => !item.discount);
        options.push({ installments, options: validNoDiscount }); // Aca entran las que no tienen descuento
        usedInterest.push(...validNoDiscount.map((item) => item.card_id));
      }
    }
  });

  // si configuran la opción de un pago en el backoffice acá es donde la agrego y la sumo al array final.
  const single = allOneInstallment?.filter((option) => option.card_id === +card_id);
  let finalArray = options.filter((option) => option.options.length > 0 && option.installments > 1).slice(0, 3);
  if (singlePayOption && singlePayOption !== '-' && single?.length && card_id && +card_id > 0) {
    const thirdOption = finalArray.slice(0, 2);
    thirdOption.push({ installments: 1, options: single });
    finalArray = [...thirdOption];
  }

  return { finalArray };
};
