import { useCallback, useMemo, useState } from 'react';
import { useField } from 'react-final-form-hooks';
import { FORM_FIELD_TYPE } from '../../../hooks/useFieldForm';
import { IFieldComponent } from '../../FieldComponent';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { APP_ECOMMERCE } from '../../../../../../config';
import { useBreakpointsState } from '../../../../../../app/hooks/useMobile';
import { SimpleSelect } from '../../../../../../commons';
import { useEmittersContext } from '../../../../../../context/EmittersContext/EmittersContextProvider';
import { Emitter, CardName, CardType, PaymentModalName, TokenizedCard, CARD_EMITER } from '../../../../../../app/models';
import { EMITTERS } from '../../../../../../app/const/Emitters';
import { EmittersView } from '../../../../../../app/views/components/EmittersView';
import { paymentInitialValue, checkoutAllValues, tokenCardInitialValue, ITokenCardInfo } from '../../../initialValues';
import { useGenerateValues } from './useGenerateValues';
import { useRolesContext } from '../../../../../../context';
import { CouponInput, TokenCardsForm, PaymentCuotesEmty } from '../components';
import { Footer } from '../components/Footer';
import { useTokenCardLogic } from './useTokenCardLogic';

export const useFields = () => {
  const { form, values } = useCheckoutFormContext();
  const { useMakeDown, useMakeUp } = useBreakpointsState();
  const { emitters } = useEmittersContext();
  const { userInfo } = useRolesContext();
  const emitterField = useField('paymentMethod.emitter', form);
  const cardField = useField('paymentMethod.card', form);
  const paymentField = useField('paymentMethod.payment', form);
  const tokenField = useField('tokenCardInfo', form);
  const { payments, loading, cards, tokenizedCards, loadingTCards } = useGenerateValues();

  const [showEmitters, setShowEmitters] = useState<boolean>(!userInfo.id && tokenizedCards.length === 0);

  const isDownSm = useMakeDown('sm');
  const isUp768 = useMakeUp(768);

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const onChangeEmitter = useCallback(() => {
    cardField.input.onChange(paymentInitialValue.card);
    paymentField.input.onChange(paymentInitialValue.payment);
  }, [cardField.input, paymentField.input]);

  const isCapresca = !!emitters.find((emiter) => emiter.card_emiter_id.toString() === EMITTERS.CATAMARCA_CREDITS.toString());
  const hiddenCards = useMemo(() => !userInfo.id || tokenizedCards.length === 0 || showEmitters, [userInfo, tokenizedCards, showEmitters]);

  useTokenCardLogic({ tokenCards: tokenizedCards, showEmitters });

  const onChangePayment = useCallback(() => {
    tokenField.input.onChange(tokenCardInitialValue);
  }, [tokenField.input]);

  const onNewPayment = useCallback(() => {
    setShowEmitters(true);
  }, []);

  const onChangeTokenCard = useCallback(
    (tokenCard: ITokenCardInfo) => {
      if (tokenCard.token === '') return;
      const emitter = emitters.find((item) => item.card_emiter_id === CARD_EMITER[tokenCard.payment_method_id].card_emiter_id);
      const emiter = {
        card_emiter_id: CARD_EMITER[tokenCard.payment_method_id].card_emiter_id,
        name: CARD_EMITER[tokenCard.payment_method_id].emitter,
        image_url: emitter?.image_url,
      };
      const card = cards.find((item) => item.card_id === CARD_EMITER[tokenCard.payment_method_id].card_id.toString());
      emitterField.input.onChange(emiter);
      const cardData = {
        card_id: CARD_EMITER[tokenCard.payment_method_id].card_id,
        name: CARD_EMITER[tokenCard.payment_method_id].emitter,
        emiter: CARD_EMITER[tokenCard.payment_method_id].card_emiter_id,
        type: CARD_EMITER[tokenCard.payment_method_id].type,
        card_image_url: card?.card_image_url || 'http//imagen.com',
        emiter_image_url: card?.emiter_image_url || 'http//imagen.com',
        match: [],
      };
      cardField.input.onChange(cardData);
    },
    [emitterField.input, cards, emitters, cardField.input],
  );

  const data: IFieldComponent[] = useMemo(
    () => [
      {
        hidden: hiddenCards || showEmitters,
        component: FORM_FIELD_TYPE.RADIO_GROUP_CUSTOM,
        xs: 12,
        title: 'Mis tarjetas',
        titleProps: { pb: 2, borderBotton: '1px solid grey' },
        componentProps: {
          row: !isDownSm,
          name: 'tokenCardInfo',
          id: 'tokenCardInfo',
          valueKey: 'token',
          options: tokenizedCards,
          customHelperText: 'Debés seleccionar una opción',
          component: TokenCardsForm,
          componentProps: { selected: valuesForm.tokenCardInfo.token },
          optionToValue: (value: string, options: TokenizedCard[]) => {
            const tokenCard = options.find((item) => item.token === value) || tokenCardInitialValue;
            onChangeTokenCard(tokenCard);
            return tokenCard;
          },
          valueToOption: (value: TokenizedCard, options: TokenizedCard[]) => options.find((item) => item.token === value.token)?.token || '',
          form,
        },
      },
      {
        hidden: !hiddenCards,
        title: 'Seleccioná el metodo de pago',
        titleProps: { pb: 2 },
        component: FORM_FIELD_TYPE.RADIO_GROUP_CUSTOM,
        xs: 12,
        componentProps: {
          row: !isDownSm,
          name: 'paymentMethod.emitter',
          id: 'paymentMethod.emitter',
          valueKey: 'card_emiter_id',
          labelKey: 'image_url',
          options: emitters,
          customHelperText: 'Debés seleccionar una opción',
          component: EmittersView,
          componentProps: {
            labelToFilter: 'card_emiter_id',
            mapper: [
              { id: 1, keys: [EMITTERS.VISA, EMITTERS.MASTER, EMITTERS.AMEX] },
              { id: 2, keys: [EMITTERS.MERCADOPAGO, EMITTERS.NARANJA, EMITTERS.CATAMARCA_CREDITS, EMITTERS.BAPRO, EMITTERS.HIPOTECARIO, EMITTERS.BNA, EMITTERS.SUPERVIELLE] },
            ],
          },
          optionToValue: (value: string, options: Emitter[]) => {
            onChangeEmitter();
            return options.find((item) => item.card_emiter_id.toString() === value.toString());
          },
          valueToOption: (value: Emitter, options: Emitter[]) =>
            options.find((item) => item.card_emiter_id?.toString() === value.card_emiter_id?.toString())?.card_emiter_id?.toString() || '',
          form,
        },
      },
      {
        xs: 12,
        hidden: valuesForm.paymentMethod.emitter.name === '' || !hiddenCards,
        title: APP_ECOMMERCE === 'detecnologia' ? 'Seleccioná el tipo' : 'Seleccioná la tarjeta',
        component: FORM_FIELD_TYPE.RADIO_GROUP,
        componentProps: {
          loading,
          form,
          valueKey: 'card_id',
          labelKey: 'nameOption',
          marginBottom: isDownSm ? '2%' : 0,
          name: 'paymentMethod.card',
          options: cards,
          customHelperText: 'Debés seleccionar una opción',
          optionToValue: (value: string, options: CardName[]) =>
            options.map(({ nameOption, ...rest }) => rest).find((item) => item.card_id.toString() === value.toString()),
          valueToOption: (value: CardType, options: CardName[]) =>
            options.find((item) => item.card_id.toString() === value.card_id.toString())?.card_id.toString() || '',
        },
      },
      {
        xs: 6,
        hidden:
          (valuesForm.paymentMethod.card.name === '' && hiddenCards) ||
          isCapresca ||
          (valuesForm.tokenCardInfo.token === '' && !hiddenCards) ||
          payments.length > 0,
        component: FORM_FIELD_TYPE.CUSTOMIZED,
        componentProps: { component: PaymentCuotesEmty },
      },
      {
        lg: 7,
        md: 7,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.GENERIC_CONTROL,
        hidden:
          (valuesForm.paymentMethod.card.name === '' && hiddenCards) ||
          isCapresca ||
          (valuesForm.tokenCardInfo.token === '' && !hiddenCards) ||
          payments.length === 0,
        title: 'Seleccioná las cuotas',
        componentProps: {
          form,
          name: 'paymentMethod.payment',
          component: SimpleSelect,
          customHelperText: 'Debés seleccionar una opción',
          componentProps: {
            placeholder: 'Seleccioná las cuotas',
            content: payments,
            valueKey: 'id',
            labelKey: 'name',
          },
          optionToValue: (value: string) => {
            const newValue = payments.find((item) => item.id === value);
            const { name, id, total_discount, ...rest } = newValue || {};
            return rest;
          },
          valueToOption: (value: PaymentModalName) => {
            const oldValue = `${value.installments} ${value.amount} ${value.original_amount}`;
            
            return payments.find((item) => item.id === oldValue)?.id || '';
          },
        },
      },
      {
        xs: 12,
        hidden: hiddenCards,
        component: FORM_FIELD_TYPE.CUSTOMIZED,
        componentProps: {
          component: Footer,
          componentProps: { selected: valuesForm.tokenCardInfo.token, onChangePayment, onNewPayment },
        },
      },
      {
        hidden: (valuesForm.paymentMethod.card.name === '' && hiddenCards) || isCapresca,
        component: FORM_FIELD_TYPE.CUSTOMIZED,
        title: 'Cupón de descuento',
        componentProps: { component: CouponInput },
        name: 'coupon.name',
      },
    ],
    [
      isUp768,
      cards,
      emitters,
      form,
      loading,
      isCapresca,
      payments,
      isDownSm,
      showEmitters,
      valuesForm.paymentMethod.card.name,
      valuesForm.paymentMethod.emitter.name,
      valuesForm.tokenCardInfo.token,
      hiddenCards,
      tokenizedCards,
      onChangePayment,
      onChangeEmitter,
      onChangeTokenCard,
      onNewPayment,
    ],
  );
  return { fields: data.filter((field) => !field.hidden), loading: loadingTCards };
};
