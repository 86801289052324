import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography, Divider, Tooltip, IconButton, Button, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { AnyObject } from 'final-form';
import { productCardStyles as useStyles } from 'src/app/views/styles';
import { getPricePublicationCart, getPricePublicationTaxCart } from '../../../../../context/CartContext/utils';
import { formatPriceNotDecimal, validateFreeShipping, getDiscountPublication, getInterest } from '../../../../../utils';
import { CartItem, defaultConfigValues } from '../../../../../app/models';
import { useCartContext } from '../../../../../context';
import { Analytics } from '../../../../../platform/analytics';
import { getInfoPublicationPayment } from '../../../../../commons/hooks/useInfoPublicationPayment';
import { useCheckoutFormContext } from '../../hooks/CheckoutFormContext';
import { useInfoCards } from '../../hooks/useInfoCards';
import { useMultiStepFormContext } from '../../../../../lib/MultiStepForm';
import { useBreakpointsState } from '../../../../../app/hooks/useMobile';
import { checkoutAllValues } from '../../initialValues';
import { PaymentFormat } from '../../types';
import { useFormContext } from '../../../../../lib/templates';

export const OrderSummay = () => {
  const { cartState, dispatch } = useCartContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { activeStep, steps, completedSteps, setActiveStep, equifaxRequestLoading } = useMultiStepFormContext();
  const { form, values, errors, handleSubmit } = useCheckoutFormContext();
  const { form: formContext } = useFormContext<AnyObject>();
  const [amountPercent, setAmountPercent] = useState<number>();
  const { sm } = useBreakpointsState();
  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);
  const {
    paymentMethod: { card, payment, emitter },
  } = valuesForm;

  const classes = useStyles();

  const valuesAcordion = useInfoCards(valuesForm, completedSteps);
  const paymentFormat = useMemo<PaymentFormat>(() => {
    let communFactor = 0;
    const totalItems = cartState.items.length;
    const preConfig = cartState.items.reduce<{ main_price: boolean; main_amount: 'original_amount' | 'amount' }>((accum, item) => {
      const { mainAmount, main_price } = getInfoPublicationPayment(item.publication);
      if (defaultConfigValues.showPrice.main_amount !== mainAmount || defaultConfigValues.showPrice.main_price !== main_price) {
        communFactor += 1;
        accum = { main_price, main_amount: mainAmount };
      }
      return accum;
    }, defaultConfigValues.showPrice);
    const config = communFactor === totalItems ? preConfig : defaultConfigValues.showPrice;
    const isCaprescaEdu = payment.reference === '19';
    const amountFinal = payment[isCaprescaEdu ? config.main_amount : 'amount'];
    const discountPorcent = payment.amount ? getDiscountPublication(amountFinal.toString(), payment.original_amount) : 0;
    const discount = payment.original_amount - amountFinal || 0;
    const interest = discountPorcent <= 0 ? getInterest(payment.original_amount, amountFinal) : 0;
    const totalPrice = cartState.items.reduce<number>((accum, item) => {
      const price = getPricePublicationCart(item.publication, payment);
      accum += +price * item.quantity;
      return accum;
    }, 0);
    const totalPriceTax = cartState.items.reduce<number>((accum, item) => {
      const price = getPricePublicationTaxCart(item.publication, payment);
      accum += +price * item.quantity;
      return accum;
    }, 0);
    const amount = totalPrice || cartState.totals.subTotal;
    const default_id = 1;
    const shipment = cartState.items.filter(({ publication }) => publication.shipment_category_id !== default_id);
    return {
      method: card,
      installments: payment.installments,
      totalAmount: amount + values.shipment_cost - discount,
      amount,
      shipping: !!shipment.length,
      discount: payment.original_amount - amountFinal || 0,
      interest,
      textPayment: payment.installments > 1 ? `cuotas${interest > 0 ? ' fijas' : ''}` : 'pago',
      textInterest: (interest === 0 && payment.installments > 1 && `sin interés`) || '',
      shipmentCost: values.shipment_cost || 0,
      totalAmountTax: totalPriceTax,
    };
  }, [payment, card, values.shipment_cost, cartState.totals.subTotal, cartState.items]);

  const onUndoAction = useCallback(
    (item: CartItem, one?: boolean) => {
      if (one) {
        dispatch.editQuantity({ id: item.publication.id, newQuantity: item.quantity + 1 });
      } else {
        dispatch.addItem(item);
      }
      dispatch.updateCartTotals();
      if (activeStep >= steps.length - 2) {
        formContext.change('paymentMethod.payment.type', 'Simulation');
      }
      closeSnackbar();
    },
    [dispatch, activeStep, steps.length, closeSnackbar, formContext],
  );

  useEffect(() => {
    form.change('coupon', checkoutAllValues.coupon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitter.card_emiter_id]);

  useEffect(() => {
    if (values.coupon.response[0] === 'total%') {
      setAmountPercent(Math.round((cartState.totals.totalPrice * values.coupon.discount) / 100));
    } else {
      setAmountPercent(0);
    }
  }, [cartState.totals.totalPrice, values.coupon.discount, values.coupon.response]);

  useEffect(() => {
    if (amountPercent) {
      const couponCopy = { ...values.coupon, amountCoupon: amountPercent };
      form.change('coupon', couponCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountPercent, cartState.totals.totalPrice]);

  const itemsReduce = () => {
    if (activeStep < steps.length - 2) return;
    setActiveStep(steps.length - 2);
    formContext.change('paymentMethod.payment.type', '');
  };

  const onRemoveItem = (item: CartItem) => {
    if (item.quantity === 1) {
      dispatch.removeItem(item.publication.id);
      Analytics.trackRemoveFromCart({ publication: item.publication, quantity: 1 });
      enqueueSnackbar('Eliminaste un producto. ¿Te arrepentiste?', {
        variant: 'success',
        action: (
          <Button color="inherit" size="small" onClick={() => onUndoAction(item)}>
            Deshacer
          </Button>
        ),
      });
    } else {
      dispatch.editQuantity({ id: item.publication.id, newQuantity: item.quantity - 1 });
      enqueueSnackbar(`Eliminaste ${item.publication.product.slice(0, 15)} x 1, ¿Te arrepentiste?`, {
        variant: 'success',
        action: (
          <Button color="inherit" size="small" onClick={() => onUndoAction(item, true)}>
            Deshacer
          </Button>
        ),
      });
    }
    dispatch.updateCartTotals();
  };

  const shipmentComponent = useCallback(() => {
    if (values.delivered !== 'store_pickup') {
      if (validateFreeShipping(paymentFormat.amount.toString() || '') && !paymentFormat.shipping) {
        return (
          <Typography variant="subtitle2">
            <Box color="success.dark">Gratis</Box>
          </Typography>
        );
      }
      if (paymentFormat.shipmentCost === 0) {
        return 'a definir';
      }
      if (paymentFormat.shipmentCost !== 0) {
        return `$${formatPriceNotDecimal(paymentFormat.shipmentCost)}`;
      }
    } else {
      return 'Exento';
    }
    return <></>;
  }, [paymentFormat, values.delivered]);

  return (
    <Box borderRadius={8} boxShadow={3} bgcolor="white">
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <Box marginBottom={1} marginTop={1} marginLeft={2}>
            <Typography variant="h6">
              <Box component="span" color="primary.dark">
                Resumen
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Divider />

        <Grid item container xs={12}>
          <Box margin={2} width="100%">
            <Grid item container direction="column">
              <Grid container direction="column" spacing={2}>
                {valuesAcordion.map((info) => (
                  <Grid key={info.id} item>
                    <Box marginBottom={!info.complete && -1} width="100%">
                      <Typography>
                        <Box component="span" fontFamily="Montserrat" fontWeight="400" color={info.complete ? '#000000' : '#9E9E9E'}>
                          <Typography>{info.title}</Typography>
                        </Box>
                      </Typography>
                      <Box mt={1}>{info.complete && info.render}</Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Box marginTop={6}>
              <Grid item container xs={12}>
                <Grid item container justify="space-between" xs={12}>
                  <Typography component="div" variant="body2" color="textPrimary">
                    <Box component="span" fontWeight="600">
                      Subtotal
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {cartState.items.map((itemCart) => (
                    <Grid item key={itemCart.publication.id} direction="row" container>
                      <Grid item xs={8}>
                        <Tooltip title={itemCart.publication.product} aria-label="product name">
                          <Box width="auto" display="inline-grid">
                            <Grid item zeroMinWidth>
                              <Typography noWrap variant="body2" color="textSecondary">
                                {`${itemCart.quantity} x ${itemCart.publication.product}`}
                              </Typography>
                            </Grid>
                          </Box>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography align="right" variant="body2" color="textSecondary">
                          {`$${formatPriceNotDecimal(getPricePublicationCart(itemCart.publication, payment))}`}
                        </Typography>
                      </Grid>

                      <Grid item xs={1} container justify="flex-end">
                        <IconButton
                          style={{ width: 22, height: 22 }}
                          onClick={() => {
                            onRemoveItem(itemCart);
                            itemsReduce();
                          }}
                        >
                          <DeleteIcon color="inherit" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {values.coupon.checked && (
                <Box marginTop={2}>
                  <Grid item container justify="space-between" xs={12}>
                    <Typography component="div" variant="body2" color="textPrimary">
                      <Box component="span" fontWeight="600">
                        Cupón de descuento
                      </Box>
                    </Typography>
                  </Grid>
                  <Box mb={2}>
                    <Grid container justify="space-between">
                      <Typography variant="subtitle2" color="textSecondary">
                        <Box component="span" color="textPrimary">
                          {values.coupon.name}
                        </Box>
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        {`- $${amountPercent ? formatPriceNotDecimal(amountPercent!) : formatPriceNotDecimal(values.coupon.amountCoupon)}`}
                      </Typography>
                    </Grid>
                  </Box>
                </Box>
              )}

              <Divider style={{ marginBottom: 8, marginTop: 8 }} />
              {paymentFormat.discount > 0 && (
                <Grid item>
                  <Box>
                    <Grid container justify="space-between">
                      <Typography variant="subtitle2">
                        <Box component="span" color="textPrimary">
                          Descuento por forma de pago
                        </Box>
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {`-$${formatPriceNotDecimal(paymentFormat.discount)}`}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              )}
              {paymentFormat.interest > 0 && (
                <Grid item>
                  <Box>
                    <Grid container justify="space-between">
                      <Typography variant="subtitle2">
                        <Box component="span" color="textPrimary">
                          Intereses de financiación
                        </Box>
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {`$${formatPriceNotDecimal(paymentFormat.interest)}`}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              )}
              <Grid item>
                <Box mb={2}>
                  <Grid container justify="space-between">
                    <Typography variant="subtitle2">
                      <Box component="span" color="textPrimary">
                        Envio
                      </Box>
                    </Typography>
                    <Typography variant="h5" color="textPrimary" />
                    {shipmentComponent()}
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={1} mb={2}>
                  <Grid container justify="space-between">
                    <Typography variant="h5">
                      <Box component="span" color="primary.dark">
                        Total
                      </Box>
                    </Typography>
                    <Typography variant="h5" color="textPrimary">
                      {`$${
                        amountPercent
                          ? formatPriceNotDecimal((paymentFormat.totalAmount || cartState.totals.totalPrice) - amountPercent)
                          : formatPriceNotDecimal((paymentFormat.totalAmount || cartState.totals.totalPrice) - values.coupon.amountCoupon)
                      }`}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography variant="caption" className={classes.taxPriceTotal}>
                      Precio sin impuestos nacionales
                    </Typography>
                    <Typography variant="body2" className={classes.taxPriceTotal}>
                      {`$${
                        amountPercent
                          ? formatPriceNotDecimal(paymentFormat.totalAmountTax - amountPercent)
                          : formatPriceNotDecimal(paymentFormat.totalAmountTax - values.coupon.amountCoupon)
                      }`}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>

              {!sm && (
                <Grid item>
                  <Button
                    fullWidth
                    disabled={Object.keys(errors as any).length !== 0 || steps[activeStep].preStep || equifaxRequestLoading}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                  >
                    {equifaxRequestLoading ? <CircularProgress size="24px" /> : activeStep < steps.length - 1 ? 'Continuar' : 'Confirmar Compra'}
                  </Button>
                </Grid>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
