import React, { useMemo, useState } from 'react';
import { Grid, Typography, useMediaQuery, Box, Divider, Theme, Link } from '@material-ui/core';
import { CONFIGURATION_ID, defaultValue, PaymentSimulation, Publication } from 'src/app/models';
import { formatPriceNotDecimal } from 'src/utils';
import { Stock } from 'src/app/models/Stock';
import { imageCarouselType } from 'src/commons';
import { ShipmentData } from 'src/screens/Public/CalculateShipments/types';
import { PaymentExtraDataModel } from 'src/app/models/Payment';
import { useInfoPublicationPayment } from 'src/commons/hooks/useInfoPublicationPayment';
import { useConfigurationsContext } from 'src/context/Configuration';
import { PriceView } from 'src/commons/components/PriceView';
import { productInfoPanelStyles as useStyles, productCardStyles as useCardStyles } from '../../styles';
import ProductInfoPanelQuantity from './ProductInfoPanelQuantity';
import ProductoVariantsPanel from './ProductVariantsPanel';
import { ReactCarousel } from '../../../../commons/ui/view/Banner/CarouselMultiple';
import { CardDiscount } from '../CardDiscount/CardDiscount';
import { FreeShipmentView, PayShipmentView } from './components';
// // import { HighLightPromo } from '../HighLightPromo';
import { FavoriteButtonView } from '../FavoriteButtonView';
import { EmittersInstallmentsView } from '../EmittersInstallmentsView';
import { ImagesLightbox } from '../../../../commons/ui/view/ImagesLightbox';

interface ProductInfoPanelProps {
  data: Publication;
  stock: Stock | undefined;
  workingStock: boolean;
  onBuyClick: () => void;
  onAddToCartClick: () => void;
  onChangeQuantity: (quantity: number) => void;
  onClickMeansPayment: () => void;
  quantity: number;
  freeShipping: boolean;
  imagesCarousel: imageCarouselType[];
  enableStock: boolean;
  availableQuantity: number;
  discount: number;
  calculateShipment: () => void;
  shipmentData: ShipmentData;
  isFavorite: boolean;
  onAddFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRemoveFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  bankOptions: PaymentSimulation[];
}

interface BestPromotionsMsgProps {
  promotion: PaymentExtraDataModel;
}

export const BestPromotionsMsg = ({ promotion }: BestPromotionsMsgProps) => (
  <Box>
    {promotion.installments}
    {` ${promotion.installments > 1 ? 'cuotas' : 'pago'} `}
    de
    <Box display="inline" fontWeight="fontWeightBold" color="success.dark" px={1}>
      {promotion.amountTotal}
    </Box>
  </Box>
);

export const ProductInfoPanel = ({
  data,
  stock,
  workingStock,
  onBuyClick,
  onAddToCartClick,
  onChangeQuantity,
  onClickMeansPayment,
  quantity,
  freeShipping,
  imagesCarousel,
  enableStock,
  availableQuantity,
  discount,
  calculateShipment,
  shipmentData,
  isFavorite,
  onAddFavorite,
  onRemoveFavorite,
  bankOptions,
}: ProductInfoPanelProps) => {
  const classes = useStyles();
  const clases = useCardStyles();
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [openLightboxIndex, setOpenLightboxIndex] = useState(-1);
  const carouselImagesArray = imagesCarousel.map((image, index) => (
    <img src={image.img_url} className={classes.img} alt="item_product" onClick={() => setOpenLightboxIndex(index)} role="presentation" />
  ));
  const imagesLightbox = useMemo(() => imagesCarousel.map((image) => image.img_url), [imagesCarousel]);
  const { mainPrice, mainAmount, alterPrice, tax } = useInfoPublicationPayment(data);
  const { configurations } = useConfigurationsContext();

  const isEnablePaymentMethods = useMemo(() => {
    const config =
      configurations.find((config) => config.general_config_id.toString() === CONFIGURATION_ID.PAYMENT_METHODS.toString()) || defaultValue;
    return config.value.enable;
  }, [configurations]);

  return (
    <Grid container direction="column">
      <Box pt={2}>
        <Grid>
          <Box component="text" color="primary.dark">
            <Typography variant="h5" className={classes.normalFontWeight} id={`${data.product}-name`}>
              {data.product}
            </Typography>
          </Box>
          {isSm && <ReactCarousel arrayComponentsToBeRender={carouselImagesArray} forceNumberRender={1} />}
          {isSm && openLightboxIndex !== -1 && (
            <ImagesLightbox images={imagesLightbox} initialActive={openLightboxIndex} onClose={() => setOpenLightboxIndex(-1)} />
          )}

          {enableStock && !!discount && (
            <Box alignItems="center" display="flex" pt={1}>
              {!isSm && (
                <>
                  <Box>
                    <Typography variant="subtitle1">
                      <Box component="text" color="textPrimary" className={classes.sale} id={`${data.id}-old-price`}>
                        {`$${formatPriceNotDecimal(data.old_price)}`}
                      </Box>
                    </Typography>
                  </Box>
                </>
              )}
              <Box borderRadius={5} px={isSm ? 0 : 1}>
                <CardDiscount discount={discount} isAbsolute={false} semiBorderRadius={false} productId={data.id} />
              </Box>
            </Box>
          )}

          {enableStock && (
            <>
              <Typography variant="h4" className={classes.priceGrid}>
                <Box color="primary.dark" mb={1} id={`${data.id}-price`}>
                  {`$${formatPriceNotDecimal(mainPrice[mainAmount])}`}
                </Box>
              </Typography>
              <Box mb={1} display="flex">
                <Typography variant="caption" className={clases.taxPriceText}>
                  Precio sin impuestos nacionales{' '}
                  {tax
                    ? `$${formatPriceNotDecimal(mainPrice[mainAmount] - mainPrice[mainAmount] * tax)}`
                    : `$${formatPriceNotDecimal(mainPrice[mainAmount])}`}
                </Typography>
                {/* <HighLightPromo isAbsolute={false} semiBorderRadius={false} highlights={data.highlights || []} productId={data.id} /> */}
              </Box>

              {isEnablePaymentMethods ? (
                <EmittersInstallmentsView bankOptions={bankOptions} data={data} alterPrice={alterPrice} />
              ) : (
                <Grid container direction="row" alignItems="center">
                  <PriceView
                    variant="subtitle1"
                    key={`${alterPrice.amountTotal}-best-promos`}
                    id={`${data.id}-best-promos`}
                    beforeText={`${alterPrice.installments} ${alterPrice.typePayment}  de`}
                    afterText={`${alterPrice.discount > 0 ? `(${alterPrice.discount}% OFF)` : ''}${
                      alterPrice.installments > 1 && alterPrice.interest === 0 ? 'sin interés' : ''
                    }`}
                    withDecimals={alterPrice.installments > 1}
                    price={alterPrice.amountTotal}
                    priceProps={{ color: 'success.dark', fontWeight: 'fontWeightBold' }}
                    afterProps={{ color: 'success.dark', fontWeight: 'fontWeightBold', pl: alterPrice.installments > 1 ? 2 : 0.5 }}
                  />
                </Grid>
              )}
              <Link href="/" component="button" variant="body2" onClick={onClickMeansPayment}>
                Ver otros medios de pago
              </Link>
            </>
          )}
          <Box my={2}>
            <FavoriteButtonView
              isFavorite={isFavorite}
              position="relative"
              label={
                <Typography variant="body2" color="primary">
                  Añadir a favoritos
                </Typography>
              }
              top={0}
              right={0}
              justify="flex-start"
              onAddFavorite={onAddFavorite}
              onRemoveFavorite={onRemoveFavorite}
            />
          </Box>

          {enableStock &&
            (freeShipping ? (
              <FreeShipmentView shipmentData={shipmentData} calculateShipment={calculateShipment} />
            ) : (
              <PayShipmentView shipmentData={shipmentData} calculateShipment={calculateShipment} />
            ))}
          {data.variants.length ? <ProductoVariantsPanel publication={data} /> : null}
          <ProductInfoPanelQuantity
            stock={stock}
            workingStock={workingStock}
            quantity={quantity}
            setQuantity={onChangeQuantity}
            onBuyClick={onBuyClick}
            onAddToCartClick={onAddToCartClick}
            enableStock={enableStock}
            availableQuantity={availableQuantity}
          />
        </Grid>
      </Box>
      {!isSm && <Divider />}
    </Grid>
  );
};
